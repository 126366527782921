<template>
    <div class="home">
        <x-header>团队预约使用管理</x-header>
        <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
            <bounce-loading></bounce-loading>
        </x-content>
        <x-content v-else  :contentStyle="{display:'block',width:'calc(100% - 32px)',padding:'16px'}">
            <div class="search_warp">
                <el-row class="list-control_warp">
                      <el-col :span="4">
                        <el-input alwaysShowClear clearable v-model="searchText" @keydown.enter.native="search" placeholder="输入机构名称/联系人姓名搜索">
                            <template slot="suffix">
                                <i @click="search" style="cursor: pointer;" class="el-input__icon base-icon-search"></i>
                            </template>
                        </el-input>
                    </el-col>
         
                </el-row>
            </div>
            <x-card>
                <el-table
                    :data="datas"
                    stripe
                    style="width: 100%">
                    <el-table-column
						prop="insName"
                        label="机构名称">
                    </el-table-column>
                    <el-table-column
						prop="workPlace"
                        label="机构办公地">
                    </el-table-column>
                    <el-table-column
						prop="userName"
                        label="联系人姓名">
                    </el-table-column>
                    <el-table-column
						prop="phone"
                        label="电话">
                    </el-table-column>
                    <el-table-column
						prop="email"
                        label="邮箱">
                    </el-table-column>
                    <el-table-column
						prop="createTime"
                        label="申请时间">
                    </el-table-column>
                    <el-table-column
                        width="80"
                        label="操作">
                        <template slot-scope="scope">
                           <el-button type="primary" size="mini" @click="detail(scope.row)">详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="page-warp">
                    <el-pagination
                        @current-change="pageChange"
                        background
                        :hide-on-single-page="total<pageSize"
                        layout="total,prev, pager, next"
					    unitText="条数据"
                        :total="total"
                        :page-size="pageSize"
                        :current-page.sync="page"
                    ></el-pagination>
                </div>
            </x-card>
        </x-content>
        <app-dialog ref="applyUseDialog" :dialogTitle="'团队预约使用详情'" originTitle staticDialog width="600px">
            <div class="bd">机构信息</div>
            <el-descriptions title="" :column="1" > 
                <el-descriptions-item label="机构名称" v-if="formItem.insName">{{ formItem.insName }}</el-descriptions-item>
                <el-descriptions-item label="机构简介"  v-if="formItem.insDesc">{{ formItem.insDesc }}</el-descriptions-item>
                <el-descriptions-item label="法定代表人"  v-if="formItem.representative">{{ formItem.representative }}</el-descriptions-item>
                <el-descriptions-item label="统一社会信用代码"  v-if="formItem.code">{{ formItem.code }}</el-descriptions-item>
                <el-descriptions-item label="单位注册地"  v-if="formItem.regPlace">{{ formItem.regPlace }}</el-descriptions-item>
                <el-descriptions-item label="单位办公地"  v-if="formItem.workPlace">{{ formItem.workPlace }}</el-descriptions-item>
                <el-descriptions-item label="营业执照"  v-if="formItem.businessLicense">{{ formItem.businessLicense }}</el-descriptions-item>
            </el-descriptions>
            <div class="bd">机构联系人信息</div>
            <el-descriptions title="" :column="1" > 
                <el-descriptions-item label="姓名"  v-if="formItem.userName">{{ formItem.userName }}</el-descriptions-item>
                <el-descriptions-item label="手机号码"  v-if="formItem.phone">{{ formItem.phone }}</el-descriptions-item>
                <el-descriptions-item label="电子邮箱"  v-if="formItem.email">{{ formItem.email }}</el-descriptions-item>
                <el-descriptions-item label="备注信息"  v-if="formItem.remarks">{{ formItem.remarks }}</el-descriptions-item>
                <el-descriptions-item label="申请时间"  v-if="formItem.createTime">{{ formItem.createTime }}</el-descriptions-item>
            </el-descriptions>
        </app-dialog>
    </div>
</template>
<script>
    import { ApplyUseList} from "../../service/applyUse"

    export default {
        name:'ApplyUseManager',
        data(){
            return {
                loading:false,
                searchText:'',
                datas:[],
                page:1,
                pageSize:10,
                total:0,
                formItem:{}
            }
        },
        filters: {
			},
        created(){
            this.getAllData();
        },
        computed:{
  
        },
        methods:{
                detail(data){
                    this.formItem = data;
                    this.$refs.applyUseDialog.show();
                },
                getAllData(){
                    this.loading = true;
                    ApplyUseList(
                        this.searchText,
                        this.page,
                        this.pageSize,
                        
                    ).then(e => {
                        let data = this.APIParse(e);
                        this.datas = data.records || [];
                        this.total = data.total || 0;
                    }).catch(e => {
                        this.$message.error(e.message)
                    }).finally(() => {
                        this.loading = false
                    })
                },
                search(){
                    this.page = 1;
                    this.getAllData();
                },
                pageChange(){
                    this.getAllData();
                },
            }   
        } 
</script>
<style lang="scss" scoped>
    .home{width: 100%;height: 100%;overflow: auto;}
    .content_warp{display: block;}
    .search_warp{width:100%;margin: 16px 0px;}
    .page-warp{width: 100%;text-align: right;margin-top: 8px;}
    .eldescriptions th{
        width: 120px;
    }
    .bd {
  font-size: 15px;
  font-weight: 700;
  border-left: 3px solid #409eff;
  padding: 2px 10px;
  margin: 20px 0px;
}

</style>
<style >
.el-descriptions__body{
    color: #121212 !important;
}
.el-descriptions-item__label:not(.is-bordered-label){
    color: #777;
}
</style>